import { Subcategory, Subcategories } from "sales-layout-library/tv";
import { salesContentEmpty } from "../../../utils/screenUtils";
import api from "../api";
import { carrito } from "../signals/carrito";
import focus from "../../../utils/focus";
import dbStylesToStylesheet from "../../../utils/dbStylesToStylesheet";
import storage from "../../../utils/storage";
import { Link } from "wouter/preact";
import { navigate } from "wouter/use-location";
import HorizontalList from "../../reusable/horizontalList";
import BackgroundImage from "../../reusable/backgroundImage";
import { Navigable } from "../../reusable/navigable";
import { useEffect, useState } from "preact/hooks";
import salesNoContentComponent from "./salesNoContent";
import { vendureCacheCleanup } from "../../../hooks/query";

const SubcategoriesComp = ({ params, config }) => {
    const [myChannel, setMyChannel] = useState(null);
    const [myDesign, setMyDesign] = useState(null);
    const [myCategories, setMyCategories] = useState(null);
    const [mySubCategories, setMySubCategories] = useState(null);

    const getSubcategories = () => {
        Promise.all([
            api.shopByToken(params.roomshop),
            api.categories(decodeURIComponent(params.roomshop)),
            api.subcategories({
                channel: params.roomshop,
                parent_id: params.category,
            }),
            api.design(config.landingPage.designID),
        ])
            .then(([channel, categories, subcategories, design]) => {
                setMyChannel(channel);
                setMyCategories(categories);
                setMySubCategories(subcategories);
                setMyDesign(design);
                config.showLoadingContent(false);
            })
            .catch((e) => {
                vendureCacheCleanup();
            });
    };

    useEffect(() => {
        getSubcategories();
    }, []);

    useEffect(() => {
        getSubcategories();
    }, [params && params.category]);

    if (!myChannel && !myCategories && !mySubCategories) {
        config.showLoadingContent(true);
        return null;
    }

    if (salesContentEmpty(myChannel, myCategories, mySubCategories)) {
        return salesNoContentComponent(myDesign?.style, "category not available");
    }

    if (mySubCategories.length === 1) {
        return (
            <div id="sales">
                <Subcategory
                    data={{
                        subcategory: mySubCategories[0],
                        channel: myChannel,
                        categories: myCategories,
                        cart: carrito.value[params.roomshop] ?? [],
                        activeCategory: params.category,
                        subcategories: mySubCategories,
                    }}
                    signals={{
                        focus,
                    }}
                    templating={{
                        css: dbStylesToStylesheet(myDesign?.style),
                        texts: config.texts,
                        languageCode: storage.get("lang", "en"),
                        projectLanguageCode: config.projectLanguageCode,
                    }}
                    injected={{
                        Navigable,
                        Link,
                        navigate,
                        List: HorizontalList,
                        BackgroundImage,
                    }}
                />
            </div>
        );
    }
    return (
        <div id="sales">
            <Subcategories
                data={{
                    channel: myChannel,
                    categories: myCategories,
                    cart: carrito.value[params.roomshop] ?? [],
                    activeCategory: params.category,
                    subcategories: mySubCategories,
                }}
                signals={{
                    focus,
                }}
                templating={{
                    css: dbStylesToStylesheet(myDesign?.style),
                    texts: config.texts,
                    languageCode: storage.get("lang", "en"),
                    projectLanguageCode: config.projectLanguageCode,
                }}
                injected={{
                    Navigable,
                    Link,
                    navigate,
                    List: HorizontalList,
                    BackgroundImage,
                }}
            />
        </div>
    );
};

export default SubcategoriesComp;
