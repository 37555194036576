import { useEffect } from "preact/hooks";
import { useDispatch } from "react-redux";
import Button from "../common/button";
import { setPopUp } from "../../actions/uiActions";
import { STB } from "../../hooks/apis/stb";
import focus from "../../utils/focus";
import { Logger } from "../../utils/logger";

const ZafiroMenu = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        Logger.remoteLog("Access to Zafiro Menu");
        focus.value.replace("btn-session");
    }, []);

    return (
        <div className="mt-8" style={{ zIndex: 999 }}>
            <Button
                insideModal={true}
                data={{
                    id: "log",
                    name: "Enable/Disable log",
                    border: true,
                    customClass: " py-2 px-4 text-lg text-center rounded float-left",
                    customStyle: {
                        width: "100%",
                    },
                    onClick: function () {
                        if (localStorage.getItem("logEnabled")) {
                            localStorage.removeItem("logEnabled");
                        } else {
                            localStorage.setItem("logEnabled", true);
                        }
                        STB.reload();
                    },
                }}
            />
            <Button
                insideModal={true}
                data={{
                    id: "session",
                    name: "Show session info",
                    border: true,
                    customClass: " py-2 px-4 mt-4 text-lg text-center rounded float-left",
                    customStyle: {
                        width: "100%",
                    },
                    onClick: function () {
                        dispatch(setPopUp("sessionInfo"));
                    },
                }}
            />
            {STB.isTizen && samsung.TEP && (
                <Button
                    insideModal={true}
                    data={{
                        id: "createAccessToken",
                        name: "create ipControl AccessToken",
                        border: true,
                        customClass: " py-2 px-4 mt-4 text-lg text-center rounded float-left",
                        customStyle: {
                            width: "100%",
                        },
                        onClick: function () {
                            STB.createAccesToken();
                        },
                    }}
                />
            )}
        </div>
    );
};

export default ZafiroMenu;
