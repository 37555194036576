import { useEffect, useState } from "preact/hooks";
import Limbo from "../../components/screen/limbo";
import { eventHandler } from "../../utils/eventHandler";
import { KEYS } from "../../utils/keys";
import {
    buildMenuContents,
    DisplayMenuContents,
    getContentStored,
    ROOMTV_CONTENTS,
    storeContentSelected,
} from "./Contents";
import focus from "../../utils/focus";
import { useDispatch, useSelector } from "react-redux";
import Tv from "../tv/tv";
import CcInstructions from "../../components/ccInstructions";
import OutOfContent from "../../components/ContactHotelStaff";
import { Texts } from "../../utils/texts";
import Signage from "./Signage";
import { navigate } from "wouter/use-location";
import { useLocation } from "wouter/preact";
import { Logger } from "../../utils/logger";
import { displayMenu } from "../../actions/uiActions";
import getCCInstructions from "../../hooks/GraphqlCalls/getCCInstructions";
import { PATHS } from "../../utils/constants";

const CommonZoneOnTv = () => {
    const dispatch = useDispatch();

    const [location] = useLocation();

    if (location.indexOf("signage") < 0) {
        navigate("/signage");
    }
    const texts = useSelector((state) => state.ui.texts);
    const showMenu = useSelector((state) => state.ui.showMenu);
    const locationData = useSelector((state) => state.status.locationData);

    const [showOptions, setShowOptions] = useState(false);
    const [castScreen, setCastScreen] = useState(false);

    const sessionOptionSelected = locationData?.roomInfo?.roomTvContents.includes(
        localStorage.getItem("commonZoneOptionKey"),
    )
        ? localStorage.getItem("commonZoneOptionKey")
        : false;

    const [optionSelected, setOptionSelected] = useState(sessionOptionSelected);

    const { menuContents, firstItemId } = buildMenuContents(locationData, texts);

    const focus_CZ_Limbo = () => {
        focus.value.replace("commonZone_display_limbo");
    };

    useEffect(() => {
        focus_CZ_Limbo();
        if (!optionSelected) {
            localStorage.setItem("commonZoneOptionKey", firstItemId);
            setOptionSelected(firstItemId);
        }
    }, []);

    useEffect(() => {
        if (optionSelected === ROOMTV_CONTENTS.CASTINSTRUCTIONSCREEN.id && !castScreen) {
            getCCInstructions(
                (data) => {
                    setCastScreen(data);
                },
                () => {
                    setCastScreen({ data: PATHS.NOLANDING });
                },
            );
        }
    }, [optionSelected]);

    useEffect(() => {
        if (showMenu) {
            openMenuOptionsContent();
        } else {
            closeMenuOptionsContent();
        }
    }, [showMenu]);

    const closeMenuOptionsContent = () => {
        if (showOptions) {
            setShowOptions(false);
            focus.value.unstack();
        }
    };

    const openMenuOptionsContent = () => {
        if (!showOptions) {
            setShowOptions(true);
            focus.value.stack();
        }
    };

    const onCommonZoneKeydown = (e) => {
        const keyData = eventHandler.getKeyData(e);
        let preventDefault = false;

        if (keyData) {
            if (keyData.type === "numeric") {
                eventHandler.stopPropagation(e);
                return;
            }
            switch (keyData.value) {
                case KEYS.enter:
                    Object.keys(ROOMTV_CONTENTS).forEach((content) => {
                        if (ROOMTV_CONTENTS[content].focus === e.target.id) {
                            storeContentSelected(ROOMTV_CONTENTS[content].id);
                            setOptionSelected(ROOMTV_CONTENTS[content].id);
                        }
                    });
                    dispatch(displayMenu(false));
                    break;
                case KEYS.back:
                    dispatch(displayMenu(false));
                    preventDefault = true;
                    break;
                default:
                    break;
            }
        }

        if (preventDefault) {
            eventHandler.stopPropagation(e);
        }
    };

    return (
        <div
            id="commonZone_display"
            className={"w-full h-full"}
            onKeyDown={(e) => {
                onCommonZoneKeydown(e);
            }}
        >
            {menuContents.length === 0 &&
            (!locationData.roomInfo.roomTvContents || locationData.roomInfo.roomTvContents.length === 0) ? (
                <OutOfContent message={Texts.translateCap(texts, "no-content-available")} />
            ) : menuContents.length === 0 &&
              (!locationData.roomInfo.roomTvContents || locationData.roomInfo.roomTvContents.length > 0) ? (
                <OutOfContent message={Texts.translateCap(texts, "no-allow-content")} />
            ) : optionSelected === ROOMTV_CONTENTS.SIGNAGESEQUENCES.id ? (
                <Signage />
            ) : optionSelected === ROOMTV_CONTENTS.TVCHANNELS.id ? (
                <Tv />
            ) : optionSelected === ROOMTV_CONTENTS.CASTINSTRUCTIONSCREEN.id ? (
                <CcInstructions ccInstructionsData={castScreen} />
            ) : null}
            <Limbo id={"commonZone_display_limbo"} fullBlock={true} />
            {showOptions && menuContents.length > 0 && (
                <DisplayMenuContents
                    menuContents={menuContents}
                    firstFocus={ROOMTV_CONTENTS[optionSelected ? optionSelected : firstItemId].focus}
                />
            )}
        </div>
    );
};

export default CommonZoneOnTv;
