import query from "../../../hooks/query";
import { getEnvVar } from "../../../utils/utils";
import memize from "memize";

type ZafiroShopsResponseType = {
    data: {
        shops: {
            id: string;
            name: string;
            token: string;
            createdAt: string;
            updatedAt: string;
            code: string;
            currencyCode: string;
            pricesIncludeTax: boolean;
            customFields: {
                priceField: "WithTax" | "WithoutTax";
                isActive: boolean | undefined;
                maxProductsPerOrder?: number;
                isOrderCancellationAllowed?: boolean;
                allowScheduledDelivery?: boolean;
                coverURL: string;
                deliverySchedule?: string;
                shippingConfig: string;
                pickupLocation: string | undefined;
                isMaxProductPerOrderActive: boolean;
                autoAcceptOrders: boolean;
            };
        }[];
    };
};

const get_zafiro_shops = (): Promise<ZafiroShopsResponseType> => {
    return new Promise((resolve, reject) => {
        query({
            query: `{
                shops {
                    id
                    name
                    token                  
                    createdAt
                    updatedAt
                    currencyCode
                    code
                    token
                    pricesIncludeTax
                    customFields {
                        isActive,
                        maxProductsPerOrder,
                        autoAcceptOrders,
                        isOrderCancellationAllowed,
                        allowScheduledDelivery,
                        coverURL,
                        deliverySchedule
                        pickupLocation
                        priceField
                        shippingConfig
                        isMaxProductPerOrderActive
                    }
                }
              }`,
            cache: true,
            validateCacheValue: (value) => {
                return value?.data?.shops?.length > 0;
            },
            propagateError: false,
            onResponse: (data) => resolve(data),
            onError: (e) => reject(e),
        });
    });
};

export default memize(async (): Promise<(Sales.Channel & { name: string })[] | []> => {
    const { data: zafiroResponse }: ZafiroShopsResponseType = await get_zafiro_shops();
    if (!zafiroResponse?.shops) {
        return [];
    }
    const roomshopId = parseInt(sessionStorage.getItem("roomshopId") || "0");
    //@ts-ignore
    sessionStorage.setItem(
        "pricesIncludeTax",
        //@ts-ignore
        String(zafiroResponse?.shops?.find((item) => item.id == roomshopId)?.customFields.priceField === "WithTax"),
    );

    const result = zafiroResponse.shops
        .map((x) => {
            return {
                id: x.id,
                name: x.name,
                createdAt: x.createdAt,
                updatedAt: x.updatedAt,
                code: x.code,
                token: x.token,
                pricesIncludeTax: x.customFields.priceField === "WithTax",
                currencyCode: x.currencyCode,
                customFields: {
                    is_active: x.customFields.isActive,
                    max_products_per_order: x.customFields.maxProductsPerOrder,
                    allow_scheduled_delivery: x.customFields.allowScheduledDelivery,
                    is_order_cancellation_allowed: x.customFields.isOrderCancellationAllowed,
                    delivery_schedule: x.customFields.deliverySchedule,
                    is_max_product_per_order_active: x.customFields.isMaxProductPerOrderActive,
                    autoAcceptOrders: x.customFields.autoAcceptOrders,
                    cover_url: x.customFields.coverURL
                        ? x.customFields.coverURL.startsWith("rgb(") || x.customFields.coverURL.startsWith("rgba(")
                            ? x.customFields.coverURL
                            : `${getEnvVar("VENDURE")}${x.customFields.coverURL}`
                        : undefined,
                    shipping_config: x?.customFields?.shippingConfig ? JSON.parse(x.customFields.shippingConfig) : null,
                    pickup_location: x.customFields.pickupLocation
                        ? JSON.parse(x.customFields.pickupLocation)
                        : [{ languageCode: "en", name: "Undefined" }],
                },
            };
        })
        .filter((shop) => shop.customFields.is_active);
    return result;
});
