import Query from "../query";

const getScreen = (screenId, designID, callBackOnResponse) => {
    if (screenId) {
        Query({
            cache: true,
            query: `{
                        content(ID:${Number(screenId)} designID:${designID}) {
                            id
                            name
                            contentStyle
                            contentWidgets{
                                ID
                                contentWidgets{
                                    ID
                                    actions
                                    data
                                    pos
                                    type
                                    widgetStyle
                                    fonts{
                                        externalURL
                                        libraryRef
                                        name
                                    }
                                }
                                data
                                pos
                                type
                                actions
                                widgetStyle
                                fonts{
                                    externalURL
                                    libraryRef
                                    name
                                }
                            }
                            devices{
                                grid{
                                    cols
                                    rows
                                }
                                type
                            }
                            pos
    
                    }
                }`,
            onResponse(res) {
                callBackOnResponse(res?.data?.content);
            },
        });
    }
};

export default getScreen;
