/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "preact/hooks";
import { useDispatch } from "react-redux";
import { setNumpadCode } from "../../actions/eventsActions";
import "../../assets/styles/calculator.css";
import Button from "./button";
import { eventHandler } from "../../utils/eventHandler";
import focus from "../../utils/focus";
import { Theme } from "../../utils/theme";
import { KEYS } from "../../utils/keys";

const Calculator = ({
    code,
    digits,
    minDigits,
    confirmText,
    confirmIcon,
    confirmBtnStyle,
    encrypt,
    error,
    setError,
    confirmAction,
}) => {
    const dispatch = useDispatch();
    const ERROR_TIMEOUT = 3000; // in milliseconds
    let errorTimeout = useRef();

    const [currentCode, setCurrentCode] = useState("");
    const [currentName, setCurrentName] = useState("");
    const numberStyle = `rounded number`;

    const borderColor = Theme.light ? Theme.colors.default.gray["300"] : Theme.colors.default.gray["600"];

    if (code) {
        minDigits = code.length;
    }
    ////////////////////////
    //Listeners
    ///////////////////////

    //set initial focus on button 1 and set confirm button name
    useEffect(() => {
        focus.value.replace(`btn-1`);
        composeConfirmBtnName();
        return () => {
            dispatch(setNumpadCode(null));
            clearTimeout(errorTimeout.current);
        };
    }, []);

    //set focus on button 1 when code change
    useEffect(() => {
        focus.value.replace(`btn-1`);
        setCurrentCode("");
        composeConfirmBtnName();
    }, [code, digits, confirmText]);

    //On current code change: update confirm button name
    useEffect(() => {
        composeConfirmBtnName();

        if (currentCode && setError) {
            setError(null);
            dispatch(setNumpadCode(null));
        }

        if (currentCode.length === (code ? code.length : digits)) {
            focus.value.replace(`btn-calculator-confirm`);
        }
    }, [currentCode]);

    //show error, remove current code and set timeout to remove error
    useEffect(() => {
        clearTimeout(errorTimeout.current);

        if (error) {
            setCurrentCode("");
            focus.value.replace(`btn-1`);

            errorTimeout.current = setTimeout(() => {
                if (setError) {
                    setError(null);
                    dispatch(setNumpadCode(null));
                }
            }, ERROR_TIMEOUT);
        }
    }, [error]);

    ////////////////////////
    //Actions
    ///////////////////////

    //Update current code on number press
    const onNumberClick = (val) => {
        if (val && currentCode.length < (code ? code.length : digits)) {
            setCurrentCode(`${currentCode}${val}`);
        }
    };

    //Update current code on digit removed
    const removeDigit = () => {
        setCurrentCode(`${currentCode.slice(0, -1)}`);
    };

    //Update confirm name
    const composeConfirmBtnName = () => {
        let chars = "";
        let iter = code ? code.length : digits;
        const limit = currentCode.length;
        for (let i = 0; i < iter; i++) {
            if (i < limit) {
                if (encrypt) {
                    chars = `${chars}*`;
                } else {
                    chars = currentCode;
                }
            } else {
                chars = `${chars} `;
            }
        }
        setCurrentName(`${chars}`);
    };

    //Action on confirm button click
    const confirm = () => {
        if (minDigits && currentCode?.length < minDigits) {
            return;
        }
        dispatch(setNumpadCode(currentCode));
        confirmAction && confirmAction(currentCode);
    };

    const onkeydown = (e) => {
        const keyData = eventHandler.getKeyData(e);
        let preventDefault = false;
        if (keyData?.type === "numeric" && currentCode.length < (code ? code.length : digits)) {
            setCurrentCode(`${currentCode}${keyData.value}`);
            preventDefault = true;
        }
        if (keyData?.value === KEYS.down && document.activeElement.id === "btn-7") {
            focus.value.replace("btn-0");
            preventDefault = true;
        }
        if (preventDefault) {
            eventHandler.stopPropagation(e);
        }
    };
    return (
        <div
            onKeyDown={(e) => {
                onkeydown(e);
            }}
        >
            <div className={"mx-auto table relative w-full"}>
                {error ? <div className={"absolute w-full text-center text-2xl"}>{error}</div> : null}
                <div className={"mx-auto table"} style={{ width: "auto" }}>
                    {currentName.split("")?.map((char) => {
                        return (
                            <div
                                style={{
                                    width: "3vw",
                                    marginRight: "0.5vw",
                                    marginBottom: "2.5vh",
                                    float: "left",
                                    borderBottom: `0.2vh solid ${borderColor}`,
                                    textAlign: "center",
                                    height: "6vh",
                                    lineHeight: "6vh",
                                }}
                            >
                                {char}
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className={`mx-auto table`} style={{ width: "auto", fontSize: "1.5vw" }}>
                <div class={`numberRow`}>
                    <Button
                        insideModal={true}
                        data={{
                            name: "1",
                            value: 1,
                            customClass: numberStyle,
                            border: true,
                            onClick: onNumberClick,
                        }}
                    />
                    <Button
                        insideModal={true}
                        data={{
                            name: "2",
                            value: 2,
                            customClass: numberStyle,
                            border: true,
                            onClick: onNumberClick,
                        }}
                    />{" "}
                    <Button
                        insideModal={true}
                        data={{
                            name: "3",
                            value: 3,
                            customClass: numberStyle,
                            border: true,
                            onClick: onNumberClick,
                        }}
                    />
                </div>
                <div class={`numberRow`}>
                    <Button
                        insideModal={true}
                        data={{
                            name: "4",
                            value: 4,
                            customClass: numberStyle,
                            border: true,
                            onClick: onNumberClick,
                        }}
                    />
                    <Button
                        insideModal={true}
                        data={{
                            name: "5",
                            value: 5,
                            customClass: numberStyle,
                            border: true,
                            onClick: onNumberClick,
                        }}
                    />{" "}
                    <Button
                        insideModal={true}
                        data={{
                            name: "6",
                            value: 6,
                            customClass: numberStyle,
                            border: true,
                            onClick: onNumberClick,
                        }}
                    />
                </div>
                <div class={`numberRow`}>
                    <Button
                        insideModal={true}
                        data={{
                            name: "7",
                            value: 7,
                            customClass: numberStyle,
                            border: true,
                            onClick: onNumberClick,
                        }}
                    />
                    <Button
                        insideModal={true}
                        data={{
                            name: "8",
                            value: 8,
                            customClass: numberStyle,
                            border: true,
                            onClick: onNumberClick,
                        }}
                    />{" "}
                    <Button
                        insideModal={true}
                        data={{
                            name: "9",
                            value: 9,
                            customClass: numberStyle,
                            border: true,
                            onClick: onNumberClick,
                        }}
                    />
                </div>
                <div class={`numberRow`}>
                    <Button
                        data={{
                            customClass: `${numberStyle} invisible `,
                        }}
                    />
                    <Button
                        insideModal={true}
                        data={{
                            name: "0",
                            value: "0",
                            customClass: numberStyle,
                            border: true,
                            onClick: onNumberClick,
                        }}
                    />
                    <Button
                        insideModal={true}
                        data={{
                            id: "calc_delete",
                            customClass: `${numberStyle} text-center`,
                            border: true,
                            icon: "backspace",
                            iconSize: "text-4xl",
                            onClick: removeDigit,
                        }}
                    />
                </div>
            </div>

            <Button
                insideModal={true}
                data={{
                    id: "calculator-confirm",
                    name: confirmText,
                    customClass: `w-full rounded text-center mt-8 p-2 button text-xl`,
                    customStyle: confirmBtnStyle,
                    disabled: minDigits && currentCode.length < minDigits,
                    border: true,
                    onClick: confirm,
                }}
            />
        </div>
    );
};

export default Calculator;
