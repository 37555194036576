/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "preact/hooks";
import { useSelector } from "react-redux";
import Query from "../../hooks/query";
import { Texts } from "../../utils/texts";
import focus from "../../utils/focus";
import { memo } from "preact/compat";
import VerticalList from "../common/verticalList";
import { parseBillData, addCurrencySimbol } from "../../utils/invocie";
import Loading from "../loading";

const Bill = () => {
    const ITEM_TYPE = "bill";

    //Store data
    const texts = useSelector((state) => state.ui.texts);
    const { room } = useSelector((state) => state.status.sessionData);
    //States
    const [guestBill, setGuestBill] = useState(false);
    const [guestBillItems, setGuestBillItems] = useState(false);
    const [error, setError] = useState(false);

    const getBill = () => {
        Query({
            query: `{
				bill(roomId: ${room.id}){
					currencySymbol
					currencyPosition
					totalAmount
					items{
						date
						description
						price
						quantity
					}
				}
			}`,
            onResponse(res) {
                console.log(res);
                if (res && res.data && res.data.bill) {
                    setGuestBill(res.data.bill);
                } else if (res?.errors.length > 0) {
                    focus.value.replace("btn-exit");
                    setError(true);
                } else {
                    focus.value.replace("btn-exit");
                }
            },
        });
    };

    useEffect(() => {
        getBill();
    }, []);

    //listeners
    useEffect(() => {
        if (guestBill) {
            setGuestBillItems(parseBillData(guestBill));
            if (guestBill.items.lenght === 0) {
                focus.value.replace("btn-exit");
            }
        }
    }, [guestBill]);

    return (
        <>
            <div className="text-center min-h-1/4">
                {guestBill ? (
                    guestBillItems ? (
                        <>
                            <div className="text-right text-xl font-500 mb-4">
                                {Texts.capitalize(texts["total"])}:{" "}
                                {addCurrencySimbol(
                                    guestBill ? guestBill.totalAmount : 0,
                                    guestBill.currencySymbol,
                                    guestBill.currencyPosition,
                                )}
                            </div>
                            <VerticalList
                                insideModal={true}
                                data={{
                                    list: guestBillItems,
                                    itemType: ITEM_TYPE,
                                    itemCustomClass: "w-full",
                                    itemCustomStyle: {
                                        marginBottom: "0.7vh",
                                        backgroundColor: "transparent",
                                    },
                                    customClass: "min-w-1/2 pr-1",
                                    itemRounded: true,
                                    maxHeight: "55vh",
                                }}
                            />
                        </>
                    ) : (
                        <div>{texts["no-pending-expenses"]}</div>
                    )
                ) : error ? (
                    <>
                        <div style={{ width: "50vw", paddingTop: "8rem" }}>{texts["bill-not-available"]}</div>
                        <div style={{ width: "50vw", paddingTop: "1vh" }}>{texts["try-again"]}</div>
                    </>
                ) : (
                    <div style={{ width: "50vw", paddingTop: "8rem" }}>
                        <Loading />
                    </div>
                )}
            </div>
        </>
    );
};

export default memo(Bill);
