import { useEffect, useRef, useState } from "preact/hooks";
import Image from "../../components/reusable/image";
import { DEFAULT, STATE, STORAGE_KEY } from "../../utils/constants";
import { calculateElapseTimeForCurrentLoop, fullContentDuration, SignageContentType } from "../../utils/signageUtils";
import { completeDasURL, formatSeconds, inStandbyMode } from "../../utils/utils";
import getScreen from "../../hooks/GraphqlCalls/getScreen";
import { SESSION } from "../../utils/session";
import Screen from "../../components/screen";
import LibraryContent from "../../components/libraryContent";
import { forceBackgroundRestore, setVideoPos } from "../../actions/uiActions";
import { useDispatch, useSelector } from "react-redux";
import { Media } from "../../hooks/apis/media";
import { Logger } from "../../utils/logger";
import OutOfContent from "../../components/ContactHotelStaff";
import { Texts } from "../../utils/texts";
import { STB } from "../../hooks/apis/stb";

const PlayCurrentSequence = ({ sequence }) => {
    const dispatch = useDispatch();
    const texts = useSelector((state) => state.ui.texts);

    const [indexContentPlaying, setIndexContentPlaying] = useState(null);
    const [currentContent, setCurrentContent] = useState(null);
    const [screenData, setScreenData] = useState(null);
    const currentSequence = useRef(sequence);

    const nextContentTimeout = useRef();
    useEffect(() => {
        findContentToPlay();
    }, []);

    useEffect(() => {
        currentSequence.current = sequence;
        setIndexContentPlaying(null);
        findContentToPlay();
    }, [sequence]);

    useEffect(() => {
        setCurrentContent(currentSequence.current?.contents?.[indexContentPlaying?.index] || null);
    }, [indexContentPlaying]);

    useEffect(() => {
        if (!currentContent) {
            return;
        }

        nextContentTimeout.current && clearTimeout(nextContentTimeout.current);
        nextContentTimeout.current = setTimeout(
            playNext,
            (indexContentPlaying.timeElapsed || currentContent.duration) * 1000,
        );
        switch (currentContent.type) {
            case "SCREEN":
                dispatch(forceBackgroundRestore());
                getScreen(currentContent.ref, SESSION.currentSignage.designId, (data) => {
                    if (!data) {
                        STB.reload();
                    }
                    setScreenData(data);
                });
                break;
            default:
                break;
        }
    }, [currentContent]);

    const playContent = () => {
        if (inStandbyMode()) {
            return <OutOfContent message={"Signage sequence in stand-by mode"} />;
        }
        switch (currentContent?.type) {
            case SignageContentType.ASSET:
            case SignageContentType.VIDEO:
                return <LibraryContent asset={currentContent.ref} jumpTo={indexContentPlaying.timeElapsed} />;
            case SignageContentType.SCREEN:
                return <Screen data={screenData} preventFocus={true} />;
            default:
                return null;
        }
    };

    const findContentToPlay = () => {
        let secondsElapsedFromCurrentLoop = calculateElapseTimeForCurrentLoop(currentSequence.current);
        let newContentToPlay = null;
        currentSequence.current.contents?.find((content, index) => {
            if (secondsElapsedFromCurrentLoop >= 0) {
                secondsElapsedFromCurrentLoop = secondsElapsedFromCurrentLoop - content.duration;
                if (secondsElapsedFromCurrentLoop < 0) {
                    newContentToPlay = { index, timeElapsed: secondsElapsedFromCurrentLoop * -1 };
                    setIndexContentPlaying(newContentToPlay);
                }
            }
        });
        return newContentToPlay;
    };

    const playNext = () => {
        if (Media && (Media.playingMedia || Media.playingChannel)) {
            Media.showVideoOnBack();
            Media.stop({ keepLastVideo: true });
            dispatch(setVideoPos(null));
        }
        if (currentSequence.current?.contents?.length == 1) {
            if (currentContent?.type == SignageContentType.VIDEO) {
                Media.replayLastVideo();
                nextContentTimeout.current = setTimeout(playNext, currentContent.duration * 1000);
            }
            //cortar sequencia ya que no hay
            return;
        }
        if (indexContentPlaying.index + 1 >= currentSequence.current.contents.length) {
            setIndexContentPlaying({ index: 0, timeElapsed: 0 });
        } else {
            setIndexContentPlaying({ index: indexContentPlaying.index + 1, timeElapsed: 0 });
        }
    };

    return <>{indexContentPlaying?.index > -1 ? playContent() : null}</>;
};

export default PlayCurrentSequence;
